<template>
  <div
    class="card w-full flex flex-col space-y-2 py-2 mb-8 sm:mb-2 rounded-sm border-t-4 text-sm md:text-base"
    :class="{ 'border-l-8 border-yellow-300 bg-yellow-50': record?.rit?.kost_luxe_vervoer > 0 }"
  >
    <!-- Uren / Opties / Shiften -->
    <div class="flex flex-col xl:flex-row justify-center items-center xl:justify-between gap-2 mb-2">
      <div class="flex flex-col lg:flex-row justify-center items-center gap-2 lg:gap-4">
        <span class="label-time inline-block">{{ timeString(record.planning_data.datetime, true) }}</span>
        <LabelRitOpties hide-km :rit="{ ...record.rit, ...record.planning_data }" />
      </div>
      <div class="flex flex-row space-x-1 sm:items-center">
        <h3 class="hidden sm:inline font-bold pr-1">Jouw shift:</h3>
        <LabelShiftUren
          vinkjes
          show-all
          :start="record?.shift?.start"
          :aangekomen="record?.shift?.aangekomen"
          :klant_ingestapt="record?.shift?.klant_ingestapt"
          :klant_afgezet="record?.shift?.klant_afgezet"
          :einde="record?.shift?.einde"
        />
      </div>
    </div>

    <div class="inline-flex flex-wrap md:flex-1 md:flex-shrink-1 md:flex md:items-center gap-1 items-center">
      <ButtonRitCombinatieStatus
        v-if="record?.planning_data?.combinatie_status > 0"
        :status="record.planning_data.combinatie_status"
        disabled
      />
      <LabelPremiumRit v-if="record?.rit?.kost_luxe_vervoer > 0" />
      <LabelFactuurAangevraagd v-if="record?.rit?.is_factuur || record?.rit?.is_factuur_nodig" />
      <ButtonAlertPersona :persona="record?.rit?.persona" />
    </div>

    <div class="flex flex-col xl:grid xl:grid-cols-2 gap-2">
      <div class="card gray inline-flex flex-col gap-1">
        <label small class="block font-bold"> Adressen </label>
        <span v-if="!record?.adressen?.length" class="text-red-500">Geen adressen ?!</span>
        <GoogleLink
          v-for="(adres, index) in record.adressen"
          :key="adres.adres + index + record.uuid"
          :icon="adres.is_luchthaven ? 'fas fa-plane' : 'fas fa-map-marker-alt'"
          :adres="adres.adres"
          class="block ml-1 mb-1"
        />
      </div>

      <ul class="card gray inline-flex flex-col gap-1">
        <li>
          <TextRitDeel
            class="font-bold"
            :is-retour="record.rit.is_retour"
            :deel-retour="record.ritten_chauffeurs.is_retour"
          />
        </li>
        <li v-if="record.planning_data.vlucht">
          <GoogleFlightStatus :vlucht="record.planning_data.vlucht" :adressen="record?.adressen">Vluchtnummer</GoogleFlightStatus>
        </li>
        <li v-if="!store.getters.hasOnderaannemerPermission">
          <i class="fas fa-money-bill mx-1 text-gray-400 w-4 text-sm"></i>
          <span class="font-semibold text-sm">Totaal te ontvangen</span>:
          <span class="text-black" :class="{ 'font-bold text-red-800': bedragTeOntvangen > 0 }">
            &euro;&nbsp;{{ formatPrice(bedragTeOntvangen) }}
          </span>
        </li>
        <li v-if="!store.getters.hasOnderaannemerPermission">
          <i class="fas fa-file-signature mx-1 text-gray-400 w-5 text-sm"></i>
          <span class="font-semibold text-sm">Contract totaalprijs</span>: &euro;&nbsp;{{
            formatPrice(record.rit.prijs)
          }}
        </li>
        <li v-if="!store.getters.hasOnderaannemerPermission && record.rit.is_retour">
          <i class="fas fa-file-signature mx-1 text-gray-400 w-5 text-sm"></i>
          <span class="font-semibold text-sm"
            >Contract {{ record.ritten_chauffeurs.is_retour ? 'heenrit' : 'retour' }}</span
          >: {{ dateTimeStringLocale(record.planning_data.datetime_linked) }}
        </li>
      </ul>

      <div class="card gray w-full flex xl:inline-flex flex-row items-start" v-if="!store.getters.hasOnderaannemerPermission">
        <div class="flex flex-col gap-1">
          <BordjeLijn :klant="record.klant" :reiziger="record.reiziger" :facturatie="record.facturatie" />
          <WagenLijn label="Wagen" :wagen="record.wagen" />
          <BedrijfThemaLogo :bedrijf="record.rit?.bedrijf_thema || 'LUCHTHAVENVERVOERKEVIN'" max-height="48" class="mr-auto inline" />
        </div>
      </div>

      <div class="card gray w-full flex flex-col md:flex-row justify-between">
        <div class="flex flex-col gap-1">
          <KlantLijn label="Klant" :klant="record.klant" />
          <KlantLijn v-if="record.reiziger" label="Reiziger" :klant="record.reiziger" />
          <FacturatieLijn v-if="record.facturatie?.id > 0" label="Bedrijf" :facturatie="record.facturatie" hidden-btw />
          <FacturatieLijn
            v-else-if="record?.rit?.is_factuur || record?.rit?.is_factuur_nodig"
            label="Bedrijf"
            :facturatie="{ name: `Geen facturatie gegevens - Navragen bij dispatch` }"
            hidden-btw
            italic
          />
          <ButtonRitPassagiers v-if="record?.rit?.is_passagier_lijst" :rit_id="record?.rit?.id" class="mt-4" />
        </div>
        <div class="flex flex-col gap-2">
          <ButtonAlertOpmerking v-show="record?.rit?.opmerking" :opmerking="record?.rit?.opmerking" />
          <ButtonRitSamenRijden :rit_id="record?.rit?.id" :is_retour="record.ritten_chauffeurs.is_retour" />
        </div>
      </div>
    </div>

    <div v-if="toonActieKnoppen" class="inline-flex flex-wrap gap-4 xl:gap-0 flex-col xl:flex-row justify-center items-center">
      <div class="flex flex-col justify-center items-center gap-1">
        <TextShiftState :shift="record.shift" />
        <PlanningButtons v-bind="record" />
        <TextShiftVergeten
          :shift-start="record?.shift?.start"
          :rit-datetime="record?.planning_data?.datetime"
        />
        <TextContractVergeten
          :getekend="!!record?.rit?.is_getekend"
          :rit-datetime="record?.planning_data?.datetime"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, watch, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { timeString, dateTimeStringLocale, isVerleden } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import useBedragTeOntvangen from './utils/useBedragTeOntvangen'
import BedrijfThemaLogo from '@/components/UI/Labels/BedrijfThemaLogo.vue'

const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))
const FacturatieLijn = defineAsyncComponent(() => import('@/components/Table/Planning/FacturatieLijn.vue'))
const WagenLijn = defineAsyncComponent(() => import('@/components/Table/Planning/WagenLijn.vue'))
const BordjeLijn = defineAsyncComponent(() => import('@/components/Table/Planning/BordjeLijn.vue'))
const PlanningButtons = defineAsyncComponent(() => import('@/components/Table/Planning/Buttons.vue'))
const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))
const ButtonAlertPersona = defineAsyncComponent(() => import('@/components/UI/Button/AlertPersona.vue'))
const ButtonRitCombinatieStatus = defineAsyncComponent(() => import('@/components/UI/Button/RitCombinatieStatus.vue'))
const ButtonRitSamenRijden = defineAsyncComponent(() => import('@/components/UI/Button/RitSamenRijden.vue'))
const ButtonRitPassagiers = defineAsyncComponent(() => import('@/components/UI/Button/RitPassagiers.vue'))
const LabelRitOpties = defineAsyncComponent(() => import('@/components/UI/Labels/RitOpties.vue'))
const LabelShiftUren = defineAsyncComponent(() => import('@/components/UI/Labels/ShiftUren.vue'))
const LabelPremiumRit = defineAsyncComponent(() => import('@/components/UI/Labels/PremiumRit.vue'))
const LabelFactuurAangevraagd = defineAsyncComponent(() => import('@/components/UI/Labels/FactuurAangevraagd.vue'))
const GoogleFlightStatus = defineAsyncComponent(() => import('@/components/Google/FlightStatus.vue'))
const GoogleLink = defineAsyncComponent(() => import('@/components/Google/Link.vue'))
const TextRitDeel = defineAsyncComponent(() => import('@/components/UI/Text/RitDeel.vue'))
const TextShiftState = defineAsyncComponent(() => import('@/components/UI/Text/ShiftState.vue'))
const TextShiftVergeten = defineAsyncComponent(() => import('@/components/UI/Text/ShiftVergeten.vue'))
const TextContractVergeten = defineAsyncComponent(() => import('@/components/UI/Text/ContractVergeten.vue'))

const store = useStore()

const props = defineProps({
  modelValue: Object,
})

const emit = defineEmits(['update:modelValue'])

const record = ref(props.modelValue)
watch(record, (val) => emit('update:modelValue', val))

const bedragTeOntvangen = useBedragTeOntvangen(record)

const toonActieKnoppen = computed(() => {
  if (!record.value?.planning_data?.datetime) return false
  if (isVerleden(record.value?.planning_data?.datetime, 1000 * 60 * 60 * 24 * 3)) return false
  return true
})
</script>
