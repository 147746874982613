<template>
  <small v-if="!list.length" class="block text-center text-gray-600 py-4">
    Geen ritten gepland op deze dag.
  </small>
  <table v-else class="w-full">
    <tbody>
      <tr
        class="w-full" v-for="(record, recordIndex) in list"
        :key="`${recordIndex}${record?.rit?.id}${record?.ritten_chauffeurs?.id}${record?.planning_data?.datetime}${record?.uuid}${record?.id}$`"
      >
        <td class="w-full">
          <RowAfspraak class="w-full" v-if="record?.type === 'AFSPRAAK'" hide-actions hide-chauffeur :modelValue="list[recordIndex]" />
          <RowPlanning class="w-full" v-else-if="record?.type === 'PLANNING'" :modelValue="list[recordIndex]" />
          <pre v-else>{{ record }}</pre>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import RowPlanning from './RowPlanning.vue'
import RowAfspraak from '@/components/Table/Kalender/RowAfspraak.vue'

const props = defineProps({
  reservaties: {
    type: Array,
    default() {
      return []
    },
  },
  afspraken: {
    type: Array,
    default() {
      return []
    },
  },
})

const list = computed(() => {
  return (props.reservaties || []).concat(props.afspraken || []).filter(el => !!el).sort((a, b) => {
    if (a.unix == b.unix && a.adressen?.length && b.adressen?.length) {
      return Number(a.adressen[0].is_luchthaven) - Number(b.adressen[0].is_luchthaven)
    }
    return a.unix - b.unix
  })
})
</script>

<style scoped>
table {
  font-size: 90%;
}
</style>
